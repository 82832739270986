import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { Tokens } from '@courseur/types'


export const useTokensStore = defineStore('tokens', () => {

    const initialTokens: Tokens = {
        accessToken: null,
        uid: null,
        client: null
    }
   
    const tokens = useStorage(process.env.VUE_APP_NAME + '-tokens', Object.assign({}, initialTokens))

    const store = (headers: any) => {
        tokens.value = {
            accessToken: headers['access-token'],
            client: headers.client,
            uid: headers.uid
        }
    }

    const reset = () => {
        tokens.value = initialTokens
    }

    return {
        tokens,
        storeTokens: store,
        reset
    }

})