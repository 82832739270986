import { Serializer, Deserializer } from 'jsonapi-serializer';
import { SerializationParams } from '.';


export const serialize = (data: any, params: SerializationParams): Promise<any> => {
    return new Promise((r) => {
        const s: any = new Serializer(params.resources, {
            attributes: params.attributes,
            keyForAttribute: 'underscore_case'
        }).serialize(data);
        s.include = params.include;
        r(s);
    });
}


export const deserialize = (data: any): Promise<any> => {
    return new Promise((r) => {
        if (!data.data) {
            r(data);
        }
        else {
            new Deserializer({
                keyForAttribute: 'camelCase'
            }).deserialize(data, (err: any, d: any) => {
                r(d);
            });
        }
    });
}




