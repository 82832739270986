import { deserialize, Http, HttpConfig, HttpError } from '@courseur/Http'
import { useTokensStore } from '@courseur/features/tokens'
import { createEventHook } from '@vueuse/core'  

const loggingOut = createEventHook<any>()

class API extends Http {


    public constructor(config: HttpConfig) {
        super()
        this.config = config
    }

    protected getRequestHeaders() {
        const tokensStore = useTokensStore()
        const headers = {
            'Accept': 'application/json',
            'Cache-Control': 'no-store no-cache must-revalidate',
            'Access-Token': tokensStore.tokens.accessToken,
            'Client': tokensStore.tokens.client,
            'Uid': tokensStore.tokens.uid
        }
        return headers
    }

    protected async onSuccess(response: any) {
        const tokensStore = useTokensStore()
        tokensStore.storeTokens(response.headers)
        const r = {
            data: await deserialize(response.data),
            meta: response.data.meta
        }
        return r
    }

    protected onError(error: HttpError): HttpError {

        if (error.status === 401 || error.status === 403) {

            console.warn('401/403 : logging out')
            const tokensStore = useTokensStore()
            tokensStore.reset()

            loggingOut.trigger(error.message)
        }
        
        return super.onError(error)
    }

    public onLoggingOut(params: any) {
        return loggingOut.on(params)
    }
}

let api: API= null

const init = (config: HttpConfig) => {
    api = new API(config)
    return api
}


export const useCourseurProAPI = () => {
    return {
        api,
        init
    }
}

