
import { ref } from 'vue'

import { useFileUploadBox } from '.'

export default {
    name: 'FileUploadBox',
    setup() {
       
        const { fileUploadBox } = useFileUploadBox()
        const { accept, dismiss, onSelectFile, visible, preview } = fileUploadBox
        const over= ref(false);
 
        const onChange = async (ev: any) => {
            onSelectFile(ev.target.files[0])
        }

        const dropFile = async (ev: any) => {
            onSelectFile(ev.dataTransfer.files[0])
            over.value = false
        }

        return {
            close,
            accept,
            dismiss,
            onChange,
            dropFile,
            over,
            preview,
            visible
        }
    }
}
