import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'

import { Card } from '@courseur/types'
import { arrayfy, BaseError, objectify, timeout } from '@courseur/utils'
import { useCourseurProAPI } from "@courseur/services/courseur-pro"

import dayjs from "dayjs";
import { useAuthStore } from '@courseur/features/auth/store'

export const useCardsStore = defineStore('cards', () => {

    const { api } = useCourseurProAPI()

    const all: Ref<{ [id: string]: Card }> = ref({})

    const getAll = computed(() =>  arrayfy(all.value))
    const getByName = computed(() => arrayfy(all.value))
    

    const getByLastTransactionDate = computed(() => {
        return getAll.value
            .sort((a, b) => {
                if(!a.lastTransactionAt) {
                    return 1
                }
                if(!b.lastTransactionAt) {
                    return -1
                }
                const dateA = dayjs(a.lastTransactionAt).valueOf()
                const dateB = dayjs(b.lastTransactionAt).valueOf()
                
                if (dateA < dateB) {
                    return 1
                }
                if (dateA > dateB) {
                    return -1
                }
                return 0
            })
    })

    const fetchCollection = async (params: any = null) => {
        try {
            const payload = Object.assign({}, params, { 
                pagination: false,
                include: 'employee' 
            })
            const response = await api.get('cards', payload)
            storeCollection(response.data)
        }
        catch (erroor) {
            //
        }
        
    }

    const fetch = async (cardId: string) => {
        try {
            const response = await api.get('cards/' + cardId, { include: 'employee' });
            store([response.data])
            return response.data
        } catch (error) {
            //
        }
    }


    const update = async (data: any) => {
        try {
            const params = Object.assign({ include: 'employee' }, data)
            const response = await api.put('cards/' + data.id, params);
            store([response.data])

            return response.data
        }
        catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }



    const assignEmployee = async (data: any) => {
        return update({
            id: data.card.id,
            employee_id: data.employee.id
        })
    }
    const removeEmployee = async (data: any) => {
        return update({
            id: data.card.id,
            employee_id: null
        })
    }
    const activate = async (data: any) => {
        try {
            const status = (data.activate) ? 'ACT' : 'BLO';
            
            all.value[data.id] = Object.assign({}, all.value[data.id], {status: data.status});

            api.put('cards/' + data.id + '/set_params', {
                id: data.id,
                status
            })

        }
        catch (error: any) {
            //
        }
    }


    const credit = async (data: any) => {
        await api.post('cards/' + data.card.id + '/credit', {
            amount: data.amount,
            include: 'employee'
        });
        await timeout(7000);
        useAuthStore().fetch()
        await fetch(data.card.id)
        
    }
    const debit = async (data: any) => {
        await api.post('cards/' + data.card.id + '/debit', {
            amount: data.amount,
            include: 'employee'
        });
        await timeout(7000);
        useAuthStore().fetch()
        await fetch(data.card.id)
        
    }

    const threshold = async (data: any) => {
        await api.put('cards/' + data.card.id, {
            threshold: data.amount,
            include: 'employee'
        });
        // await timeout(3200);
        await fetch(data.card.id)
    }



    const storeCollection = (cards: Card[]) => {
        all.value = objectify(cards)
    }
    const store = (cards: Card[]) => {
        all.value = Object.assign({}, all.value, objectify(cards))
    }

    const reset = () => {
        all.value = {}
    }

    return {
        all,
        byName: getByName,
        byLastTransactionDate: getByLastTransactionDate,
        fetchCollection,
        fetch,
        update,
        activate,
        assignEmployee,
        removeEmployee,
        credit,
        debit,
        threshold,
        storeCollection,
        reset
    }
})