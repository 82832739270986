import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a8e4f44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-box" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["wrapper", {'zoomed': $setup.zoomed}]),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.dismiss && $setup.dismiss(...args)))
        }, [
          ($setup.images.length > 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.next(-1)), ["stop"]))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "chevron-left" })
              ]))
            : _createCommentVNode("", true),
          (_openBlock(), _createElementBlock("img", {
            key: $setup.images[$setup.current],
            src: $setup.images[$setup.current],
            alt: "",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($setup.zoom && $setup.zoom(...args)), ["stop"])),
            ref: "currentImage",
            class: _normalizeClass({'can-zoom': true})
          }, null, 8, _hoisted_2)),
          ($setup.images.length > 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.next(1)), ["stop"]))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "chevron-right" })
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}