
import { App } from 'vue';
import FileUploadBox from './FileUploadBox.vue';

interface PluginOptions {
    componentName?: string;
}

export let params: PluginOptions = {
    componentName: 'FileUploadBox',
};

export function install(app: App, args: PluginOptions): void {
    params = Object.assign(params, args)
    app.component(params.componentName, FileUploadBox);
}