import {ref, Ref} from 'vue'

let instance: any = null

 const createInstance = () => {

    const images: Ref<string[]> = ref([])
    const current: Ref<number> = ref(0)
    const visible: Ref<boolean> = ref(false)
    

    const show = (imgs: string[], curr = 0) => {
        images.value = imgs
        current.value = curr
        visible.value = true
    }

    const dismiss = () => {
        images.value = []
        visible.value = false
    }

    return {
        images,
        current,
        visible,
        show,
        dismiss
    }
}

export const useImageBox = () => {
    if (instance) {
        return {
            imageBox: instance
        }
    }
    instance = createInstance()
    return {
        imageBox: instance
    }
}