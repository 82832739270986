import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { initComponents, initLayouts } from './components';
import { initValidations } from './validation';

import Notifications from '@kyvg/vue3-notification'

import { alertBox, fileUploadBox, imageBox, layoutSystem } from '@courseur/ui'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/browser";

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useCourseurProAPI } from '@courseur/services/courseur-pro';
import { reset } from './stores';
import { createPinia } from 'pinia';

if(process.env.NODE_ENV === 'production') {
    Sentry.init({
        // Vue: app,
        dsn: "https://ba4c97347af648528e285d39d8f25159@o99640.ingest.sentry.io/5619202",
        integrations: [new BrowserTracing()],
        release: process.env.VUE_APP_VERSION,
        environment: process.env.NODE_ENV,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

const app = createApp(App);
app.use(createPinia())
app.use(router);

app.use(alertBox);
app.use(imageBox);
app.use(fileUploadBox);
app.use(Notifications)

// const proApiUrl = (process.env.NODE_ENV === 'development')
//     ? 'https://pro-api-stg.courseur.com/'
//     : 'https://pro-api.courseur.com/';

const proApiUrl = 'https://pro-api.courseur.com/';

app.use(layoutSystem, {
    defaultLayout: 'Dashboard'
})

useCourseurProAPI()
    .init({
        url: proApiUrl,
        version: 'v1'
    })
    .onLoggingOut(() => {
        reset()
        router.push('/sign-in')
    })


initComponents(app);
initLayouts(app)
initValidations();
dayjs.locale('fr')

app.mount('#app');

export default app;
