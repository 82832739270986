import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'

import { Employee } from '@courseur/types'
import { BaseError, objectify } from '@courseur/utils'
import { useCourseurProAPI } from "@courseur/services/courseur-pro"

export const useEmployeesStore = defineStore('employees', () => {

    const { api } = useCourseurProAPI()

    const all: Ref<{ [id: string]: Employee }> = ref({})
    const active: Ref<string[]> = ref([])
    const inactive: Ref<string[]> = ref([])

    const getActive = computed(() => active.value.map((id) => all.value[id]))
    const getInactive = computed(() => inactive.value.map((id) => all.value[id]))

    const fetchCollection = async (status: number = null) => {
        try {
            const response = await api.get('employees', {
                status,
                pagination: false,
                include: 'card'
            });
            store(response.data)
    
            if (status === 0) {
                active.value = response.data.map((c:Employee) => c.id)
            }
            if (status === 1) {
                inactive.value = response.data.map((c:Employee) => c.id)
            }
        }
        catch (erroor) {
            //
        }
        
    }

    const fetch = async (employeeId: string) => {
        try {
            const response = await api.get('employees/' + employeeId, { include: 'card' });
            store([response.data])
            return response.data
        } catch (error) {
            //
        }
    }

    const create = async (data: any) => {
        try {
            const response = await api.post('employees', data);
            store([response.data])
            
            // TODO add to active array, sort by last name
            fetchCollection(0)
            return response.data
        }
        catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const update = async (data: any) => {
        try {
            const params = Object.assign({}, data)
            const response = await api.put('employees/' + data.id, params)
            store([response.data])

            return response.data
        }
        catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const createOrUpdate = async (data: any) => {
        if (data.id) {
            return await update(data)
        } else {
            return await create(data)
        }
    }


    const activateAutoConfirm = async (data: any) => {
        return update({
            id: data.employee.id,
            auto_confirm: data.autoConfirm
        })
    }

    const archive = async (data: any) => {
        try {
            await update({
                id: data.employee.id,
                status: 1
            })
            const index = active.value.findIndex((c) => data.employee.id === c)
            active.value.splice(index, 1)
            
        }
        catch (error: any) {
            //
        }
    }
    const activate = async (data: any) => {
        try {
            await update({
                id: data.employee.id,
                // refund_amount: data.refundAmount,
                status: 0
            })
            const index = inactive.value.findIndex((c) => data.employee.id === c)
            inactive.value.splice(index, 1)
            
        }
        catch (error: any) {
            //
        }
    }
    const remove = async (employee: Employee) => {
        try {
            await api.delete('employees/' + employee.id)
            const index = inactive.value.findIndex((c) => employee.id === c)
            inactive.value.splice(index, 1)
            
        }
        catch (error: any) {
            //
        }
    }

    const addClient = async (employeeId: string, clientId: string) => {
        const response = await api.post('employees/' + employeeId + '/add_client', {
            client_id: clientId
        })
        return response.data
    }
    const removeClient = async (employeeId: string, clientId: string) => {
        const response = await api.post('employees/' + employeeId + '/remove_client', {
            client_id: clientId
        })
        return response.data
    }

    const fetchClients = async (employeeId: string) => {
        const response = await api.get('employees/' + employeeId + '/clients')
        return response.data
    }


    const storeCollection = (clients: Employee[]) => {
        all.value = objectify(clients)
    }
    const store = (clients: Employee[]) => {
        all.value = Object.assign({}, all.value, objectify(clients))
    }

    const reset = () => {
        all.value = {}
        active.value = []
        inactive.value = []
    }

    return {
        all,
        active: getActive,
        inactive: getInactive,
        fetchCollection,
        fetch,
        create,
        update,
        activateAutoConfirm,
        createOrUpdate,
        archive,
        activate,
        delete: remove,
        addClient,
        removeClient,
        fetchClients,
        storeCollection,
        reset
    }
})