interface BaseErrorParams {
    title: string;
    message: string;
}

export class BaseError extends Error {
    public title: string;
    public message: string;
    constructor(params: BaseErrorParams, ...args: any[]) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...args)

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BaseError)
        }

        // this.name = 'BaseError'
        // Custom debugging information
        this.title = params.title;
        this.message = params.message;

    }
}