import { HttpErrorParams } from './types/HttpErrorParams';

export class HttpError extends Error {
    public name: 'HttpError';
    public isAppError = false;
    public isServerError = false;
    public isNetworkError = false;
    public isUnknownError = false;
    public status: number;
    public message: string;
    constructor(params: HttpErrorParams, ...args: any[]) {
        
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...args);
        
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, HttpError);
        }
        this.isAppError = params.isAppError || false;
        this.isServerError = params.isServerError || false;
        this.isNetworkError = params.isNetworkError || false;
        this.isUnknownError = params.isUnknownError || false;
        this.status = params.status || null;
        this.message = params.message || 'Erreur inconnue';
    }
}