import { defineStore } from 'pinia'
import { useCourseurProAPI } from '@courseur/services/courseur-pro'
import { useAgenciesStore } from '..'

export const useOnboardingsStore = defineStore('onboardings', () => {

    const { api } = useCourseurProAPI()
    const agenciesStore = useAgenciesStore()

    const updateInformations = async (data) => {
        const response = await api.put('onboardings/' + data.agency.onboarding.id, {
            informations: JSON.stringify(data.informations)
        })
        
        const agency = Object.assign({}, data.agency, {
            onboarding: response.data
        })
        agenciesStore.store([agency])
        return response
    }
    const updateStatus = async ( data) => {
        const response = await api.put('onboardings/' + data.agency.onboarding.id, {
            status: data.status
        })

        const agency = Object.assign({}, data.agency, {
            onboarding: response.data
        })
        agenciesStore.store([agency])
        return response
    }
    const addDocument = async (data) => {
        const response = await api.post('documents', data)
        return response
    }
    const deleteDocument = async (id) => {
        const response = await api.delete('documents/'+id)
        return response
    }

    return {
        updateInformations,
        updateStatus,
        addDocument,
        deleteDocument
    }
})
