import { defineStore } from 'pinia'
import { useCourseurProAPI } from '@courseur/services/courseur-pro'

export const useAgencyManagersStore = defineStore('agency-managers', () => {

    const { api } = useCourseurProAPI()

    const fetchCollection = async () => {
        const response = await api.get('admins', {
            pagination: false
        });
        return response.data;
    }
    const create = async (manager: any) => {
        const response = await api.post('admins', manager);
        return response.data;
    }
    const update = async (manager: any) => {
        const response = await api.put('admins/' + manager.id, manager);
        return response.data;
    }
    const updateNotification = async (manager: any) => {
        const response = await api.put('admins/' + manager.id, {
            email_notification : manager.emailNotification
        });
        return response.data;
    }
    const updateTwoAuth = async (manager: any) => {
        const response = await api.put('admins/' + manager.id, {
            double_factor_auth: manager.doubleFactorAuth
        });
        return response.data;
    }
    const remove = async (managerId: string) => {
        const response = await api.delete('admins/' + managerId);
        return response.data;
    }

    return {
        fetchCollection,
        create,
        update,
        updateNotification,
        updateTwoAuth,
        delete: remove
    }
})
