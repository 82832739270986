
import { useRouter } from 'vue-router';
export default {
    name: 'CBackButton',
    props: {
        src: {
            type: String,
            default: '/',
            required: false,
        }
    },
    setup(props: any, context: any) {
        const router = useRouter();
        const dismiss = () => {
            router.replace(props.src);
        }
        return {
            context,
            dismiss
        }
    }
}

