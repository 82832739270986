import { computed, Ref, ref } from 'vue';
import { defineStore } from 'pinia'

import { useCourseurProAPI } from "@courseur/services/courseur-pro";
import { useTokensStore } from '@courseur/features/tokens';
import { BaseError } from '@courseur/utils/src';
import { User } from '@courseur/types';

export const useAuthStore = defineStore('auth', () => {

    const { api } = useCourseurProAPI()

    const initialUser: User = {
        id: null,
        email: null,
        firstname: null,
        lastname: null
    }

    const user: Ref<any> = ref(initialUser)

    const isLoggedIn = computed(() => {
        const tokensStore = useTokensStore()
        return !!tokensStore.tokens.client
    })


    const signin = async (data: any) => {
        try {
            const { email, password, otp_code } = data;
            const payload: any = {
                email,
                password,
            }
            if (otp_code)  {
                payload.otp_code = otp_code
            }
            const response = await api.post('auth/sign_in', payload, {
                apiVersion: ''
            });
            if (response.data.id) {
                store(response.data)
            }
            return response.data
        }
        catch (err: any) {
            throw new BaseError({
                title: 'Erreur',
                message: err.message
            });
        }
    }
    const signup = async (data: any) => {
        const { firstname, lastname, email, password } = data;
        const response = await api.post('auth', {
            firstname,
            lastname,
            email,
            password

        }, {
            apiVersion: ''
        });
        if (response.data.id) {
            store(response.data)
        }
        return response.data
        // commit('user/store', response.data, { root: true });
        // commit('user/store', response.data, { root: true });
    }

    const fetch = async () => { 
        const response = await api.get('admins/current')
        store(response.data)
    }

    const resetPassword = async (data: any) => {
        const { email } = data;
        await api.post('admins/reset_password', {
            email,
        });
    }
    const changePassword = async (data: any) => {
        const { password, token } = data;
        await api.post('admins/change_password', {
            password,
            token
        });
    }


    const otp = async (data: any) => {
        await api.get('guests/otp', data);
    }

    const otpCheck = async (data: any) => {
        const { value, smsCode } = data;
        const response = await api.post('guest_auth/sign_in', {
            value,
            otp_code: smsCode
        }, {
            apiVersion: '',
        });
        
        return response.data
    }

    const store = (data: any) => {
        user.value = Object.assign({}, data)
    }

    const reset = () => {
        user.value = initialUser
    }

    return {
        user,
        isLoggedIn,
        fetch,
        signin,
        signup,
        changePassword,
        resetPassword,
        otp,
        otpCheck,
        store,
        reset
    }

})