import { computed, Ref, ref } from 'vue'
import { BaseError, objectify } from '@courseur/utils'
import { defineStore } from 'pinia'
import { useCourseurProAPI } from "@courseur/services/courseur-pro"
import { Payment } from '@courseur/types'
import dayjs from "dayjs"

export const usePaymentsStore = defineStore('payments', () => {

    const { api } = useCourseurProAPI()

    const all: Ref<{ [id: string]: Payment }> = ref({})
    const byMonth: Ref<{[key:string]: string[]}> = ref({})
    const deposits: Ref<string[]> = ref([])


    const getByMonth = (params: any) => {
        return (byMonth.value[params.month]) 
            ? byMonth.value[params.month].map(id => all.value[id])
            : []
    }
    
    const getDeposits = computed(() => {
        return deposits.value.map(id => all.value[id])
    })

    const totalDeposits = computed(() => {
        return getDeposits.value
            .filter(deposit => (deposit.status !== 'refunded' && deposit.status !== 'failed'))
            .reduce((acc, curr) => {
                return acc + parseFloat(curr.amount.split(',').join('.').split(' ').join(''))
            }, 0)
    })

    const fetchCollection = async (data: any = null) => {
        const params = Object.assign({}, data, { include: 'transactions,client', pagination: false })
        const response = await api.get('payments', params)
        store(response.data)
        if(data.payment_type === 'automatic') {
            const month = (data.start_at) ? dayjs(data.start_at).format('YYYY-MM') : 'all'
            byMonth.value = Object.assign({}, byMonth.value, {
                [month] : response.data.map((p: Payment) => p.id)
            })
        }
        else {
            deposits.value = response.data.map((d: Payment) => d.id)
        }
    } 
    const fetch = async (paymentId: string) => {
        const response = await api.get('payments/' + paymentId)
        store([response.data])
    }

    const fetchDepositsConfirmation = async (data: any) => {
        const response = await api.getFile('clients/'+ data.clientId +'/deposit_confirmation');
        return response;
    }


    const create = async (data: any) => {
        try {
            const response = await api.post('payments/', {
                description: data.description,
                amount: data.amount,
                client_id: data.client.id,
                payment_type: data.paymentType, 
                payment_method: data.paymentMethod
            });

            store([response.data])
            return response.data
        }
        catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            });
        }
    }
    const markAsRefunded = async (payment: Payment) => {
        const response = await api.put('payments/' + payment.id + '/refund')
        store([response.data])
    }

    const storeCollection = (payments: Payment[]) => {
        all.value = objectify(payments)
    }
    const store = (payments: Payment[]) => {
        all.value = Object.assign({}, all.value, objectify(payments))
    }

    const reset = () => {
        all.value = {}
        byMonth.value = {}
        deposits.value = []
    }
    const resetMonths = () => {
        byMonth.value = {}
    }

    return {
        all,
        deposits: getDeposits,
        byMonth: getByMonth,
        totalDeposits,
        fetchCollection,
        fetch,
        fetchDepositsConfirmation,
        create, 
        markAsRefunded,
        reset,
        resetMonths,
    }
})