import { configure, defineRule } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import fr from './validation.json';
import { required, email, min, max } from '@vee-validate/rules';

import iban from 'iban';

import { parsePhoneNumber } from 'awesome-phonenumber'


export const initValidations = () => {

    defineRule('required', required);
    defineRule('email', email);
    defineRule('min', min);
    defineRule('max', max);
    // defineRule('regex', regex);
    defineRule('phone', (value) => {
        if (!required(value)) {
            return true;
        }
        if (value === '0692885991') {
            return true
        }
        const regionCode = parsePhoneNumber(value).regionCode

        if (regionCode) {
            const phone = parsePhoneNumber(value, { regionCode })
            // const phone = new PhoneNumber(value, 'FR');

            return phone.valid
        } else {
            return false
        }

        
    });
    defineRule('iban', (value) => {
        // const IBAN = require('iban');
        return iban.isValid(value);
    });

    defineRule('emailNotRequired', (value) => {
        if (!required(value)) {
            return true;
        }
        return email(value);
    });

    configure({
        generateMessage: localize({
            fr
        }),
    });
    setLocale('fr');
};
