import Avatar from '@courseur/components/Avatar.vue'
import CButton from '@courseur/components/buttons/CButton.vue'
import CLink from '@courseur/components/buttons/CLink.vue'
import CRouterLink from '@courseur/components/buttons/CRouterLink.vue'
import CBackButton from '@courseur/components/buttons/CBackButton.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faBell, faCog, faEdit, faEyeSlash, faPhoneAlt, faHome, faIdCard, faPlus, faIdBadge, faShoppingCart, faStoreAlt, faUserFriends, faCreditCard, faClipboardList, faTrashAlt, faTimes, faEnvelope, faChevronRight, faChevronLeft, faQuestion, faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { App } from 'vue'

import Centered from '@courseur/ui/layouts/Centered.vue'
import Dashboard from '@/layouts/Dashboard.vue'


export const initComponents = (app: App) => {
    app.component('avatar', Avatar)
    app.component('CButton', CButton)
    app.component('CLink', CLink)
    app.component('CRouterLink', CRouterLink)
    app.component('CBackButton', CBackButton)    

    library.add(faAngleDown, faBell, faCog, faEdit, faEyeSlash, faPhoneAlt, faIdCard, faHome, faPlus, faIdBadge, faShoppingCart, faStoreAlt, faUserFriends, faCreditCard, faClipboardList, faTrashAlt, faTimes, faEnvelope,  faChevronRight, faChevronLeft, faQuestion, faComment)
    app.component('font-awesome-icon', FontAwesomeIcon)
}

export const initLayouts = async (app: App) => {
    app.component('Centered', Centered)
    app.component('Dashboard', Dashboard)
}
