import { App } from 'vue';
import LayoutSystem from './LayoutSystem.vue';

interface PluginOptions {
    componentName?: string;
    defaultLayout: string;
    layouts: any[];
}

export let params: PluginOptions = {
    componentName: 'LayoutSystem',
    defaultLayout: '',
    layouts: []
};

export function install(app: App, args: PluginOptions): void {
    params = Object.assign(params, args)
    app.component(params.componentName, LayoutSystem);
}