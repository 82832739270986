import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import dayjs from 'dayjs';
import { useAuthStore } from '@/stores';


const routes: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/download',
        component: () => import('@/pages/download/Download.vue'),
    },
    {
        path: '/account',
        redirect: '/account/home'
    },
    // {
    //     path: '/account/home',
    //     name: 'account-home',
    //     component: () => import('@/pages/account/Home.vue'),
    //     meta: { requiresAuth: true }
    // },
    // {
    //     path: '/account/general',
    //     name: 'account-general',
    //     component: () => import('@/pages/account/general/General.vue'),
    //     meta: { requiresAuth: true },
    //     redirect: '/account/general/company',
    //     children: [
    //         {
    //             path: 'company',
    //             name: 'account-company',
    //             component: () => import('@/pages/account/general/Company.vue'),
    //             meta: { requiresAuth: true }
    //         },
    //         {
    //             path: 'profile',
    //             name: 'account-profile',
    //             component: () => import('@/pages/account/general/Profile.vue'),
    //             meta: { requiresAuth: true }
    //         },
    //         {
    //             path: 'payment',
    //             name: 'account-payment',
    //             component: () => import('@/pages/account/general/Payment.vue'),
    //             meta: { requiresAuth: true }
    //         },
    //         {
    //             path: 'invoices',
    //             name: 'account-invoices',
    //             component: () => import('@/pages/account/general/Invoices.vue'),
    //             meta: { requiresAuth: true }
    //         }
    //     ]
    // },
    // {
    //     path: '/account/agencies',
    //     name: 'account-agencies',
    //     component: () => import('@/pages/account/agencies/Agencies.vue'),
    //     meta: { requiresAuth: true },
    //     children: [
    //         {
    //             path: '',
    //             name: 'agencies',
    //             component: () => import('@/pages/cards/card/Default.vue'),
    //             meta: { requiresAuth: true }
    //         },
    //         {
    //             path: ':id',
    //             name: 'agency',
    //             component: () => import('@/pages/account/agencies/Show.vue'),
    //             meta: { requiresAuth: true }
    //         },
    //         {
    //             path: ':id/create',
    //             name: 'agency-create',
    //             component: () => import('@/pages/account/agencies/create/Create.vue'),
    //             meta: { requiresAuth: true },
    //             children: [
    //                 {
    //                     path: 'intro',
    //                     name: 'agency-create-intro',
    //                     component: () => import('@/pages/account/agencies/create/Intro.vue'),
    //                     meta: { requiresAuth: true }
    //                 },
    //                 {
    //                     path: 'legal',
    //                     name: 'agency-create-legal',
    //                     component: () => import('@/pages/account/agencies/create/Legal.vue'),
    //                     meta: { requiresAuth: true }
    //                 },
    //                 {
    //                     path: 'representative',
    //                     name: 'agency-create-representative',
    //                     component: () => import('@/pages/account/agencies/create/Representative.vue'),
    //                     meta: { requiresAuth: true }
    //                 },
    //                 {
    //                     path: 'user',
    //                     name: 'agency-create-user',
    //                     component: () => import('@/pages/account/agencies/create/User.vue'),
    //                     meta: { requiresAuth: true }
    //                 },
    //                 {
    //                     path: 'stakeholders/:sid',
    //                     name: 'agency-create-stakeholders',
    //                     component: () => import('@/pages/account/agencies/create/Stakeholders.vue'),
    //                     meta: { requiresAuth: true }
    //                 },
    //                 {
    //                     path: 'offers',
    //                     name: 'agency-create-offers',
    //                     component: () => import('@/pages/account/agencies/create/Offers.vue'),
    //                     meta: { requiresAuth: true }
    //                 },
    //                 {
    //                     path: 'pending',
    //                     name: 'agency-create-pending',
    //                     component: () => import('@/pages/account/agencies/create/Pending.vue'),
    //                     meta: { requiresAuth: true }
    //                 },
    //             ]
    //         }
    //     ]
    // },
    {
        path: '/home',
        component: () => import('@courseur/ui/layouts/DashboardMain.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@/pages/home/Home.vue'),
                meta: { 
                    requiresAuth: true,
                    displaysMonthlyPayments: true,
                    sidebar: false
                },
            }
        ]
    },
    {
        path: '/transactions',
        component: () => import('@courseur/ui/layouts/DashboardMain.vue'),
        children: [
            {
                path: '',
                name: 'transactions',
                component: () => import('@/pages/transactions/Transactions.vue'),
                meta: { 
                    requiresAuth: true,
                    displaysMonthlyTransactions: true,
                    sidebar: false
                }
            }
        ]
    },

    {
        path: '/cards',
        component: () => import('@courseur/ui/layouts/DashboardMain.vue'),
        
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'cards',
                components: {
                    default: () => import('@/pages/cards/Home.vue'),
                    secondarySidebar: () => import('@/pages/cards/Nav.vue'),
                },
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                components: {
                    default: () => import('@courseur/ui/layouts/RouterViewWrapper.vue'),
                    secondarySidebar: () => import('@/pages/cards/Nav.vue'),
                },
                children: [    
                    {
                        path: '',
                        name: 'card',
                        component: () => import('@/pages/cards/Show.vue'),
                        meta: { 
                            requiresAuth: true,
                            displaysMonthlyTransactions: true
                        }
                    },
                    {
                        path: 'threshold',
                        name: 'card-threshold',
                        component: () => import('@/pages/cards/Threshold.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'credit',
                        name: 'card-credit',
                        component: () => import('@/pages/cards/CreditDebit.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'debit',
                        name: 'card-debit',
                        component: () => import('@/pages/cards/CreditDebit.vue'),
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    },

    {
        path: '/clients',
        component: () => import('@courseur/ui/layouts/DashboardMain.vue'),
        
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'clients',
                components: {
                    default: () => import('@/pages/clients/Home.vue'),
                    secondarySidebar: () => import('@/pages/clients/Nav.vue'),
                },
                meta: { requiresAuth: true },
            },
            {
                path: 'create',
                name: 'client-create',
                components: {
                    default: () => import('@/pages/clients/CreateEdit.vue'),
                    secondarySidebar: () => import('@/pages/clients/Nav.vue'),
                },
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                components: {
                    default: () => import('@courseur/ui/layouts/RouterViewWrapper.vue'),
                    secondarySidebar: () => import('@/pages/clients/Nav.vue'),
                },
                children: [
                    {
                        path: '',
                        name: 'client',
                        component: () => import('@/pages/clients/Show.vue'),
                        meta: { 
                            requiresAuth: true,
                            displaysMonthlyTransactions: true,
                            displaysMonthlyPayments: true
                        }
                    },
                    {
                        path: 'edit',
                        name: 'client-edit',
                        component: () => import('@/pages/clients/CreateEdit.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'bank-accounts',
                        name: 'client-bank-accounts',
                        component: () => import('@/pages/clients/bankAccounts/BankAccounts.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'deposit',
                        name: 'client-deposit',
                        component: () => import('@/pages/clients/bankAccounts/Deposit.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'guests/create',
                        name: 'client-guest-create',
                        component: () => import('@/pages/clients/guests/CreateEdit.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'guests/:guestId/edit',
                        name: 'client-guests-edit',
                        component: () => import('@/pages/clients/guests/CreateEdit.vue'),
                        meta: { requiresAuth: true }
                    },
                ]
            },
        ]
    },

    {
        path: '/employees',
        component: () => import('@courseur/ui/layouts/DashboardMain.vue'),
        
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'employees',
                components: {
                    default: () => import('@/pages/employees/Home.vue'),
                    secondarySidebar: () => import('@/pages/employees/Nav.vue'),
                },
                meta: { requiresAuth: true },
            },
            {
                path: 'create',
                name: 'employee-create',
                component: () => import('@/pages/employees/CreateEdit.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                components: {
                    default: () => import('@courseur/ui/layouts/RouterViewWrapper.vue'),
                    secondarySidebar: () => import('@/pages/employees/Nav.vue'),
                },
                children: [
                    {
                        path: '',
                        name: 'employee',
                        component: () => import('@/pages/employees/Show.vue'),
                        meta: { 
                            requiresAuth: true,
                            displaysMonthlyTransactions: true 
                        }
                    },
                    
                    {
                        path: 'edit',
                        name: 'employee-edit',
                        component: () => import('@/pages/employees/CreateEdit.vue'),
                        meta: { requiresAuth: true }
                    },
                ]
            },
        ]
    },
    {
        path: '/settings',
        redirect: '/settings/bank-account',
        component: () => import('@courseur/ui/layouts/DashboardMain.vue'),
        children: [
            {
                path: 'bank-account',
                name: 'bank-account',
                components: {
                    default: () => import('@/pages/settings/BankAccount.vue'),
                    secondarySidebar: () => import('@/pages/settings/Nav.vue'),
                },
                meta: { requiresAuth: true }
            },
            {
                path: 'managers',
                name: 'managers',
                components: {
                    default: () => import('@/pages/settings/managers/Managers.vue'),
                    secondarySidebar: () => import('@/pages/settings/Nav.vue'),
                }, 
                meta: { requiresAuth: true }
            },
            {
                path: 'managers/create',
                name: 'managers-create',
                components: {
                    default: () => import('@/pages/settings/managers/CreateEdit.vue'),
                    secondarySidebar: () => import('@/pages/settings/Nav.vue'),
                },
                meta: { requiresAuth: true }
            },
            {
                path: 'support',
                name: 'support',
                components: {
                    default: () => import('@/pages/settings/Support.vue'),
                    secondarySidebar: () => import('@/pages/settings/Nav.vue'),
                },
                meta: { requiresAuth: true }
            }
        ]
    },
   
    {
        path: '/sign-in',
        name: 'signIn',
        component: () => import('@/pages/auth/SignIn.vue'),
        meta: { 
            layout: 'Centered'
        },
    },
    {
        path: '/sign-up',
        name: 'signUp',
        component: () => import('@/pages/auth/SignUp.vue'),
        meta: { 
            layout: 'Centered'
        },
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('@/pages/auth/PasswordReset.vue'),
        meta: { 
            layout: 'Centered'
        },
    },
    {
        path: '/password-change',
        name: 'password-change',
        component: () => import('@/pages/auth/PasswordChange.vue'),
        meta: { 
            layout: 'Centered'
        },
    },
    // {
    //     path: '/espace-client/sign-in',
    //     name: 'espace-client-sign-in',
    //     component: () => import('@/pages/guest/SignIn.vue'),
    // },
    // {
    //     path: '/espace-client/check-code',
    //     name: 'espace-client-check-code',
    //     component: () => import('@/pages/guest/OptCheck.vue'),
    // },
    // {
    //     path: '/espace-client',
    //     name: 'espace-client',
    //     component: () => import('@/pages/guest/Guest.vue'),
    //     meta: { requiresGuestAuth: true },
    //     children: [
    //         {
    //             path: '',
    //             name: 'guest',
    //             component: () => import('@/pages/guest/components/GuestDefault.vue'),
    //             meta: { requiresGuestAuth: true },
    //         },
    //         {
    //             path: ':id',
    //             name: 'guest-client',
    //             component: () => import('@/pages/guest/client/Show.vue'),
    //             meta: { 
    //                 requiresGuestAuth: true,
    //                 displaysMonthlyTransactions: true,
    //                 displaysMonthlyPayments: true
    //             },
    //         },
    //         {
    //             path: ':id/payments',
    //             name: 'guest-client-payments',
    //             component: () => import('@/pages/guest/client/Show.vue'),
    //             meta: { requiresGuestAuth: true },
    //         }
    //     ]
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {

    if (to.meta.requiresAuth && !useAuthStore().isLoggedIn) {
        next('/sign-in');
    }
    else if (to.meta.displaysMonthlyTransactions && !to.query.t_month || to.meta.displaysMonthlyPayments && !to.query.p_month ) {
        const month = dayjs().format('YYYY-MM')
        if (to.meta.displaysMonthlyTransactions && !to.query.t_month) {
            to.query.t_month = month
        }
        if (to.meta.displaysMonthlyPayments && !to.query.p_month) {
            to.query.p_month = month
        }

        next(to)
    }
    else {
        next();
    }
});

export default router;
