import { useAgenciesStore } from '@courseur/features/agencies/store'
import { useAgencyManagersStore } from './agency-managers'
import { useAuthStore } from '@courseur/features/auth/store'
import { useCardsStore } from '@courseur/features/cards/store'
import { useClientsStore } from '@courseur/features/clients/store'
import { useEmployeesStore } from '@courseur/features/employees/store'
import { useOnboardingsStore } from './onboardings'
import { usePaymentsStore } from '@courseur/features/payments/store'
import { useTransactionsStore } from '@courseur/features/transactions/store'

let refreshInterval: number

const init = async () => {
    refreshInterval = setInterval(() => {
        useCardsStore().fetchCollection()
        useAuthStore().fetch()
    }, 1000 * 60 * 3)
}

const changeAgency = async (agencyId: string) => {
    await useAgenciesStore().setCurrent(agencyId)
    useCardsStore().reset()
    useClientsStore().reset()
    useEmployeesStore().reset()
    usePaymentsStore().reset()
    useTransactionsStore().reset()
    useAuthStore().fetch()
}

const reset = () => {
    clearInterval(refreshInterval)
    useAgenciesStore().reset()
    useCardsStore().reset()
    useClientsStore().reset()
    useEmployeesStore().reset()
    usePaymentsStore().reset()
    useTransactionsStore().reset()
}

export {
    init,
    useAgenciesStore,
    useAgencyManagersStore,
    useAuthStore,
    useCardsStore,
    useClientsStore,
    useEmployeesStore,
    useOnboardingsStore,
    usePaymentsStore,
    useTransactionsStore,
    reset,
    changeAgency
}