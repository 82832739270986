import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'

import { Client } from '@courseur/types'
import { BaseError, objectify } from '@courseur/utils'
import { useCourseurProAPI } from "@courseur/services/courseur-pro"

export const useClientsStore = defineStore('clients', () => {

    const { api } = useCourseurProAPI()

    const all: Ref<{ [id: string]: Client }> = ref({})
    const active: Ref<string[]> = ref([])
    const inactive: Ref<string[]> = ref([])

    const getActive = computed(() => active.value.map((id) => all.value[id]))
    const getInactive = computed(() => inactive.value.map((id) => all.value[id]))

    const fetchCollection = async (status: number = null) => {
        try {
            const response = await api.get('clients', {
                status: status.toString(),
                pagination: false,
            })
            store(response.data)
    
            if (status === 0) {
                active.value = response.data.map((c:Client) => c.id)
            }
            if (status === 1) {
                inactive.value = response.data.map((c:Client) => c.id)
            }
        }
        catch (erroor) {
            //
        }
        
    }

    const fetch = async (clientId: string) => {
        try {
            const response = await api.get('clients/' + clientId)
            store([response.data])
            return response.data
        } catch (error) {
            //
        }
    }

    const create = async (data: any) => {
        try {
            const response = await api.post('clients', data)
            store([response.data])
            
            // TODO add to active array, sort by last name
            fetchCollection(0)
            return response.data
        }
        catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const update = async (data: any) => {
        try {
            const params = Object.assign({}, data)
            const response = await api.put('clients/' + data.id, params)
            store([response.data])

            return response.data
        }
        catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const createOrUpdate = async (data: any) => {
        if (data.id) {
            return await update(data)
        } else {
            return await create(data)
        }
    }


    const addBankAccount = async (data: any) => {
        try {
            data.bankAccount.iban = data.bankAccount.iban.split(' ').join('')
            data.bankAccount.bic = data.bankAccount.bic.split(' ').join('')
            const response = await api.post('clients/' + data.client.id + '/bank_accounts', data.bankAccount)
            store([response.data])
        }
        catch (error: any) {
            //
        }
    }

    const archive = async (data: any) => {
        try {
            await update({
                id: data.client.id,
                // refund_amount: data.refundAmount,
                status: 1
            })
            const index = active.value.findIndex((c) => data.client.id === c)
            
            active.value.splice(index, 1)
            
        }
        catch (error: any) {
            //
        }
    }
    const activate = async (data: any) => {
        try {
            await update({
                id: data.client.id,
                // refund_amount: data.refundAmount,
                status: 0
            })
            const index = inactive.value.findIndex((c) => data.client.id === c)
            inactive.value.splice(index, 1)
            
        }
        catch (error: any) {
            //
        }
    }
    const remove = async (client: Client) => {
        try {
            await api.delete('clients/' + client.id)
            const index = inactive.value.findIndex((c) => client.id === c)
            inactive.value.splice(index, 1)
            
        }
        catch (error: any) {
            //
        }
    }

    const addGuest = async (data: any) => {
        const params = Object.assign({}, data.guest)
        const response = await api.post('clients/' + data.id + '/guests', params)
        store([response.data])
    }
    const updateGuest = async (data: any) => {
        const params = Object.assign({}, data.guest)
        const response = await api.put('clients/' + data.id + '/guests/' + data.guest.id, params)
        store([response.data])
    }

    const deleteGuest = async (data: any) => {
        const params = Object.assign({}, data.guest)
        const response = await api.delete('clients/' + data.id + '/guests/' + data.guest.id, params)
        store([response.data])
    }



    const storeCollection = (clients: Client[]) => {
        all.value = objectify(clients)
    }
    const store = (clients: Client[]) => {
        all.value = Object.assign({}, all.value, objectify(clients))
    }

    const reset = () => {
        all.value = {}
        active.value = []
        inactive.value = []
    }

    return {
        all,
        active: getActive,
        inactive: getInactive,
        fetchCollection,
        fetch,
        create,
        update,
        createOrUpdate,
        addBankAccount,
        archive,
        activate,
        delete: remove,
        addGuest,
        updateGuest,
        deleteGuest,
        storeCollection,
        reset
    }
})