import { computed, Ref, ref } from 'vue'
import { BaseError, objectify } from '@courseur/utils'
import { defineStore } from 'pinia'
import { useCourseurProAPI } from '@courseur/services/courseur-pro'
import { Transaction } from '@courseur/types'
import dayjs from 'dayjs'

export const useTransactionsStore = defineStore('transactions', () => {
    const { api } = useCourseurProAPI()

    const all: Ref<{ [id: string]: Transaction }> = ref({})
    const byMonth: Ref<{ [key: string]: string[] }> = ref({})
    const importants: Ref<string[]> = ref([])

    const getByMonth = (params: any) => {
        return byMonth.value[params.month] ? byMonth.value[params.month].map((id) => all.value[id]) : []
    }

    const getImportants = computed(() => {
        return importants.value.map((id) => all.value[id])
    })
    const getRequireAction = computed(() => {
        const transactions = getImportants.value.filter(
            (transaction) =>
                transaction.status === 'creation_declined' ||
                transaction.status === 'created' ||
                transaction.status === 'awaiting_confirmation'
        )
        return transactions
    })
    const getAwaiting = computed(() => {
        const transactions = getImportants.value.filter(
            (transaction) => transaction.status === 'confirmed' || transaction.status === 'awaiting_payment'
        )
        return transactions
    })

    const fetchCollection = async (data: any = null) => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.get('transactions', params)
            store(response.data)

            const month = data.start_at ? dayjs(data.start_at).format('YYYY-MM') : 'all'
            byMonth.value = Object.assign({}, byMonth.value, {
                [month]: response.data.map((p: Transaction) => p.id),
            })
        } catch (error) {
            //
        }
    }
    const fetchImportantCollection = async () => {
        try {
            const response = await api.get('transactions', {
                statuses: [
                    // 'creation_declined',
                    'created',
                    'awaiting_confirmation',
                    'confirmed',
                    'awaiting_payment',
                    'problem',
                ],
                visible: true,
                include: 'card,client,employee',
                pagination: false,
            })
            store(response.data)

            importants.value = response.data.map((t: Transaction) => t.id)
        } catch (error) {
            //
        }
    }

    const fetch = async (transactionId: string) => {
        try {
            const response = await api.get('transactions/' + transactionId, { include: 'card,client,employee' })
            store([response.data])
        } catch (error) {
            //
        }
    }
    const refresh = async (transactionId: string) => {
        try {
            await api.get('transactions/' + transactionId + '/refresh')
            await fetch(transactionId)
            // store([response.data])
        } catch (error) {
            //
        }
    }

    const fetchPdf = async (data: any) => {
        const params = Object.assign({}, data)
        const response = await api.getFile('transactions/pdf', params)
        return response
    }

    const fetchCsv = async (data: any) => {
        const response = await api.getFile('transactions/csv', data)
        return response
    }
    const fetchXls = async (data: any) => {
        const response = await api.getFile('transactions/xls', data)
        return response
    }

    const fetchAccounting = async (data: any) => {
        const response = await api.getFile('transactions/accounting_export', data)
        return response
    }

    const update = async (data: any) => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.put('transactions/' + data.id, params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const addReceipt = async (data: any) => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.post('transactions/' + data.id + '/receipts', params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const removeReceipt = async (data: any) => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.delete('transactions/' + data.id + '/receipts', params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const confirm = async (data: any) => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.put('transactions/' + data.id + '/confirm', params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const remindEmployee = async (data: any) => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.get('transactions/' + data.id + '/employee_reminder', params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const refunded = async (data: any) => {
        try {
            const params = Object.assign(
                {},
                {
                    payment_id: data.transaction.lastPayment.id,
                    payment_type: 'manual', // deposit, automatic, manual
                    payment_method: data.method, // direct_debit, credit_card, check, cash, wiretransfer
                },
                { include: 'card,client,employee', pagination: false }
            )
            const response = await api.put('transactions/' + data.transaction.id + '/refunded', params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const assignClient = async (data: any) => {
        return update({
            id: data.id,
            client_id: data.clientId,
        })
    }
    const mask = async (data: any) => {
        return update({
            id: data.id,
            visible: false,
        })
    }

    const forceRefresh = async () => {
        await api.get('transactions/force_refresh', { include: 'card,client,employee', pagination: false })
        // dispatch('auth/current', null, { root: true });
        // commit('storeCollection', response.data);
    }

    const create = async (data: any) => {
        try {
            const response = await api.post('payments/', {
                description: data.description,
                amount: data.amount,
                client_id: data.client.id,
                payment_type: data.paymentType,
                payment_method: data.paymentMethod,
            })

            store([response.data])
            return response.data
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur',
            })
        }
    }

    const storeCollection = (transactions: Transaction[]) => {
        all.value = objectify(transactions)
    }
    const store = (transactions: Transaction[]) => {
        all.value = Object.assign({}, all.value, objectify(transactions))
    }

    const reset = () => {
        all.value = {}
        byMonth.value = {}
    }
    const resetMonths = () => {
        byMonth.value = {}
    }

    return {
        all,
        byMonth: getByMonth,
        requireAction: getRequireAction,
        awaiting: getAwaiting,
        fetchCollection,
        fetchImportantCollection,
        fetch,
        refresh,
        fetchPdf,
        fetchCsv,
        fetchXls,
        fetchAccounting,
        forceRefresh,
        create,
        addReceipt,
        removeReceipt,
        confirm,
        remindEmployee,
        refunded,
        assignClient,
        mask,
        reset,
        resetMonths,
    }
})
