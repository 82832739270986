
import { App } from 'vue';
import ImageBox from './ImageBox.vue';

interface PluginOptions {
    componentName?: string;
}

export let params: PluginOptions = {
    componentName: 'ImageBox',
};

export function install(app: App, args: PluginOptions = {}): void {
    params = Object.assign(params, args)
    app.component(params.componentName, ImageBox);
}
