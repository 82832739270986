
import { App } from 'vue';
import AlertBox from './AlertBox.vue';
import { params } from './params';
import { AlertBoxPluginOptions } from './types';

export function install(app: App, args: AlertBoxPluginOptions = {}): void {
    Object.entries(args).forEach((entry) => params.set(...entry));
    app.component(args.componentName || 'AlertBox', AlertBox);
}
