import {ref, Ref} from 'vue'

let instance: any = null

 const createInstance = () => {

    const visible: Ref<boolean> = ref(false)

    const message: Ref<string> = ref('')
    const buttons: Ref<any[]> = ref([])
    

    const show = (params: any) => {
        message.value = params.message
        buttons.value = params.buttons

        visible.value = true
    }

    const dismiss = () => {
        visible.value = false
        message.value = ''
        buttons.value = []
    }

    return {
        buttons,
        message,
        visible,
        show,
        dismiss
    }
}

export const useAlertBox = () => {
    if (instance) {
        return {
            alertBox: instance
        }
    }
    instance = createInstance()
    return {
        alertBox: instance
    }
}