interface CreateFileParams {
    filename: string
    filetype: string
    data: any,
}

//'application/pdf'

export const createFile = (params: CreateFileParams) => {
    const blob = new Blob([params.data], { type: params.filetype })
    if (window.navigator['msSaveOrOpenBlob']) {
        window.navigator['msSaveBlob'](blob, params.filename)
    }
    else {
        const elem = window.document.createElement('a')
        elem.href = window.URL.createObjectURL(blob)
        elem.download = params.filename
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
    }
}

declare global {
    interface Navigator {
        msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void
        msSaveBlob: (blobOrBase64: Blob | string, filename: string) => void
    }
  }