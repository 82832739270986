export const objectify = (data: any[]): { [id: string]: any } => {
    const o: any = {};
    data.forEach((d) => {
        o[d.id] = d;
    });
    return o;
}

export const arrayfy = (data: { [id: string]: any }): any[] => {
   return Object.keys(data).map(key => data[key])
}