import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { Agency } from '@courseur/types'
import { BaseError, objectify } from '@courseur/utils'
import dayjs from 'dayjs'
import { useCourseurProAPI } from '@courseur/services/courseur-pro'
import { useAuthStore } from '../../auth/store'

export const useAgenciesStore = defineStore('agencies', () => {

    const { api } = useCourseurProAPI()

    const all: Ref<{ [id: string]: Agency }> = ref({})
    
    const getByName = computed(() => {
        return Object.keys(all.value)
            .sort((a, b) => {

                const A = all.value[a]
                const B = all.value[b]
                
                const nameA = (A.displayName || A.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                const nameB = (B.displayName || B.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
            .map(key => all.value[key])
    })

    const getByCreationDate = computed(() => {
        return Object.keys(all.value)
            .sort((a, b) => {

                const A = all.value[a]
                const B = all.value[b]
                
                const dateA = dayjs(A.createdAt).valueOf()
                const dateB = dayjs(B.createdAt).valueOf()
                
                if (dateA < dateB) {
                    return -1
                }
                if (dateA > dateB) {
                    return 1
                }
                return 0
            })
            .map(key => all.value[key])
    })

    const getByLastTransactionDate = computed(() => {
        return Object.keys(all.value)
            .map(key => all.value[key])
            .filter(a => a.active && a.lastTransactionAt)
            .sort((a, b) => {

                const dateA = dayjs(a.lastTransactionAt).valueOf()
                const dateB = dayjs(b.lastTransactionAt).valueOf()
                
                if (dateA < dateB) {
                    return -1
                }
                if (dateA > dateB) {
                    return 1
                }
                return 0
            })
    })

    const getByUser = (userId: string) => {
        return Object.keys(all.value)
            .map(key => all.value[key])
            .filter(a => a.user.id === userId)
    }

    const fetchCollection = async () => {
        const response = await api.get('agencies', {
            pagination: false,
            include: 'company',
        })
        storeCollection(response.data)
    }
    const fetch = async (id: string) => {
        const response = await api.get('agencies/' + id, {
            include: 'onboarding,documents,company'
        })
        store([response.data])
    }

    const create = async (data: any) => {
        try {
            const response = await api.post('agencies', data)
            // should store the response but getCollection is OKfor now
            store([response.data])
            return response.data
        }
        catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const update = async (data: any) => {
        try {
            const payload = Object.assign({
                include: 'onboarding,documents,company'
            }, data);
            const response = await api.put('agencies/' + data.id, payload);
            store([response.data])
            return response.data;
        }
        catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const createOrUpdate = async (data: any) => {
        if (data.id) {
            return await update(data)
        } else {
            return await create(data)
        }
    }

    const overrideAnytimeCredentials = async (data: any) => {
        const response = await api.put('agencies/' + data.agency.id + '/update_anytime_account', {
            email: data.email,
            phone_number: '33' + data.phone.substring(1) // (33651804744)
        })
        const agency = Object.assign({}, data.agency, response.data)
        store([agency])
    }

    const resetAnytimeCredentials = async (data: any) => {
        const response = await api.put('agencies/' + data.agency.id + '/reset_anytime_account')
        const agency = Object.assign({}, data.agency, response.data)
        store([agency])
    }
    
    const updateMollieToken = async (data: any)  => {
        const response = await api.post('agencies/' + data.agencyId + '/mollie_token', {
            mollie: true,
            code: data.code
        })
        const agency = Object.assign({}, data.agency, response.data)
        store([agency])
    }
    const updateSteps = async (data: any)  => {

        // move that to own store
        const response = await api.put('onboardings/' + data.agency.onboarding.id, {
            steps: JSON.stringify(data.steps)
        })

        const agency = Object.assign({}, data.agency, {
            onboarding: response.data
        })
        store([agency])
        return response;
    }


    const setCurrent = async (agencyId: string) => {
        const response = await api.put('admins/set_override_agency_id', {
            agency_id: agencyId
        })
        // commit('user/store', response.data, { root: true })
        // commit('cards/reset', null, { root: true })
        // commit('clients/reset', null, { root: true })
        // commit('employees/reset', null, { root: true })
        // commit('transactions/reset', null, { root: true })
        // dispatch('getCollection')
    }

    const current = computed(() => {
        const authStore = useAuthStore()
        return all.value[authStore.user.agency.id]
    })

    const storeCollection = (agencies: Agency[]) => {
        all.value = objectify(agencies)
    }
    const store = (agencies: Agency[]) => {
        all.value = Object.assign({}, all.value, objectify(agencies))
    }

    const reset = () => {
        all.value = {}
    }

    return {
        all,
        current,
        byCreationDate: getByCreationDate,
        byLastTransactionDate: getByLastTransactionDate,
        byName: getByName,
        byUser: getByUser,
        fetch,
        fetchCollection,
        createOrUpdate,
        update,
        overrideAnytimeCredentials,
        resetAnytimeCredentials,
        updateMollieToken,
        updateSteps,
        setCurrent,
        reset,
        store
    }
})



//     async create({ commit }, data) {

//         const response = await api.post('agencies', {
//             name: data.name,
//             // company_id: rootState.user.company.id,
//             admin_id: data.user.id,
//             include: 'onboarding,documents'
//         })
//         response.data.user = {
//             id: data.user.id,
//             firstname: data.user.firstname,
//             lastname: data.user.lastname,
//             email: data.user.email
//         }

//         // const response2 = await api.put('onboardings/' + response.data.onboarding.id, {
//         //     informations: JSON.stringify({
//         //         legal: {
//         //             kbisProof: [],
//         //             statusesProof: []
//         //         },
//         //         representative: {
//         //             identityProof: [],
//         //             addressProof: []
//         //         },
//         //         user: {
//         //             firstname: rootState.user.firstname,
//         //             lastname: rootState.user.lastname,
//         //             email: rootState.user.email,
//         //             identityProof: [],
//         //             addressProof: []
//         //         },
//         //         stakeholders: []
//         //     }),
//         //     steps: JSON.stringify({
//         //         items: []
//         //     })
//         // })
//         // response.data.onboarding = response2.data


//         commit('store', [response.data])
//         return response.data
//     },
