
import { computed, ref, Ref, watch } from 'vue'

import { useImageBox } from '.'

export default {
    name: 'ImageBox',
    setup() {

        const zoomed: Ref<boolean> = ref(false)

        const currentImage = ref(null)

        const { imageBox } = useImageBox()
        const { images, visible, dismiss, current } = imageBox

        const zoom = () => {

            if (!zoomed.value && currentImage.value.naturalHeight > currentImage.value.height) {
                zoomed.value = !zoomed.value
                return
            }
            if(zoomed.value) {
                zoomed.value = false
                return
            }
        }
        const canZoom = computed(() => {
            if (!currentImage.value) {
                return false
            }
            return currentImage.value.naturalHeight > currentImage.value.height
        })

        const next = (value: number) => {
            current.value += value
            if (current.value > images.value.length-1) {
                current.value = 0
            }
            if (current.value < 0) {
                current.value = images.value.length-1
            }
        }

        watch(images, () => {
            zoomed.value = false
        })

        return {
            images,
            currentImage,
            current,
            visible,
            next,
            canZoom,
            zoom,
            zoomed,
            dismiss
        }
    }
}
